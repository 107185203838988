const ar = {
  supportingYouWithWhatYouNeed: 'ندعمك بما تحتاجه وقتما تشاء.',
  sydYourDailyLifeQualityCompanion: 'SYD ™ - رفيق جودة الحياة اليومية',
  signUpNow: 'اشترك الآن',
  joinSydToday: 'انضم إلى SYD اليوم ',
  firstName: 'الاسم الأول',
  lastName: 'الاسم الاخير',
  gender: 'جنس',
  birthDate: 'تاريخ الميلاد',
  yourEmail: 'بريدك الالكتروني',
  confirmYourEmail: 'قم بتأكيد بريدك الإلكتروني',
  yourPassword: 'كلمة مرورك',
  confirmYourPassword: 'قم بتأكيد كلمة مرورك',
  signUp: 'اشتراك',
  byContinuingToUseSYD: 'من خلال الاستمرار في استخدام syd ، فإنك توافق على',
  termsAndConditions: 'الأحكام والشروط',
  bothUppercaseAndLowercaseLetters: 'كلا من الأحرف الكبيرة والصغيرة',
  atLeast8Characters: 'اشترك الآن أحرف على الأقل',
  mixtureOfLettersAndNumbers: 'مزيج من الحروف والأرقام',
  atLeastOneSpecialCharacter:
    'ما لا يقل عن حرف خاص واحد ، على سبيل المثال ، @ # ؟',
  yourDailyLifeQualityCompanion:
    'رفيقك اليومي في جودة الحياة يدعمك بما تحتاجه عندما تحتاجه',
  billingInformation: 'معلومات البطاقة',
  nameOnTheCard: 'الاسم على البطاقة',
  cardNumber: 'رقم البطاقة',
  expiry: 'تاريخ انتهاء الصلاحية',
  cvc: 'رمز التحقق من البطاقة (CVC)',
  continue: 'اكمل',
  yourCardWontBeChargedYet: 'لن يتم تحصيل الرسوم من بطاقتك حتى الآن',
  addressLine1: 'العنوان السطر وَاحِد',
  addressLine2: 'العنوان السطر اِثْنَان',
  townCity: 'البلدة / المدينة',
  postalZipCode: 'الرمز البريدي',
  selectACountry: 'اختر دولة',
  summary: 'الملخص',
  monthlySubscription: 'اشتراك شهري',
  total: 'مجموع',
  nextPayment: 'الدفعة التالية',
  activateMyAccount: 'تفعيل حسابي',
  congratulationAndWelcomeToSYD: 'مبروك ومرحبا بكم في SYD!',
  toAccessSYD:
    'للوصول إلى syd ، قم بتنزيل التطبيق وقم بتسجيل الدخول باستخدام عنوان بريدك الإلكتروني وكلمة المرور:',
  downloadFromApple: 'قم بالتنزيل من Apple Appstore',
  downloadFromGoogle: 'تنزيل من متجر Google Playstore',
  hide: 'إخفاء',
  show: 'إظهار',
  male: 'ذكر',
  female: 'أنثى',
  nonBinary: 'غير ثنائي',
  egMyGP50: 'e.g. MyGP50',
  yourMyGPAccessCode: 'Your MyGP Access Code',
  discountCode: 'كود الخصم',
  yourDiscountCode: 'كود الخصم الخاص بك',
  oneOffPayment: 'مرة واحدة الدفع',
  deliveryInformation: 'معلومات التوصيل',
  thisFieldIsRequired: 'هذه الخانة مطلوبه',
  invalidEmail: 'بريد إلكتروني خاطئ',
  thisEmailIsAlreadyRegistered:
    'عنوان البريد الإلكترونى هذا مسجل بالفعل. حاول تسجيل الدخول معها.',
  passwordDoesNotMeet: 'كلمة المرور لا تفي بمتطلبات القوة',
  passwordsDoNotMatch: 'كلمة المرور غير مطابقة',
  emailsDoNotMatch: 'عنوان البريد الإلكتروني غير مطابق',
  birthDateCannotBeInTheFuture: 'لا يمكن أن يكون تاريخ الميلاد في المستقبل',
  invalidCardNumber: 'رقم البطاقة غير صالحة',
  invalidExpiryDate: 'تاريخ انتهاء الصلاحية غير صالح',
  cardExpiryIsInThePast: 'انتهاء صلاحية البطاقة في الماضي',
  invalidCVC: 'رمز التحقق من البطاقة (CVC) غير صالح',
  anErrorOccuredDiscountCode: 'رمز الوصول الذي أدخلته غير مؤهل لهذا العرض.',
  birthDateCannotBeAfter: 'لا يمكن أن يكون تاريخ الميلاد بعد {{0}}',
};

export default ar;
