import { Theme, makeStyles } from '@material-ui/core';
import { environment } from '../../common/environments';

const useTextInputStyles = makeStyles<Theme, { smallSpacing: boolean }>(
  (theme: Theme) => {
    return {
      root: {
        width: '100%',

        '& .MuiFormHelperText-root.Mui-error': {
          fontFamily: 'Inter',
          fontSize: '12px',
          color: 'red',
          marginTop: 0,
          marginLeft: 0,
        },

        '& .Mui-error': {
          color: 'red',
          alignSelf: 'stretch',
          textAlign: environment.language === 'ar' ? 'right' : 'left',
          marginRight: 0,
        },
      },

      datePicker: {
        width: '100%',

        '& .MuiInputBase-root': {
          height: 60,
        },
      },

      input: {
        borderWidth: 0,
        boxShadow: 'inset 0px 1px 1px 1.5px rgba(0, 0, 0, 0.0800371)',
        borderRadius: '2px',

        fontSize: '16px',
        fontFamily: 'Inter',
      },

      label: {
        fontFamily: 'Inter',
        fontSize: '10px',
        marginBottom: 5,
        color: '#262626',
      },

      labelNoBottomMargin: {
        marginBottom: 2,
      },

      container: (props) => ({
        flexGrow: 1,
        marginTop: theme.spacing(props.smallSpacing ? 2 : 4),
      }),

      rowContainer: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',

        '&:hover': {
          cursor: 'pointer',
          opacity: 0.7,
        },

        '& label:hover': {
          cursor: 'pointer',
          opacity: 0.7,
        },
      },
    };
  }
);

export default useTextInputStyles;
