import { AppThunk, PersonalData } from '../../common/types';
import axios from 'axios';
import moment from 'moment';
import { environment } from '../../common/environments';
import { ShoppingCart } from '../../common/networkTypes';
import { shoppingCartActions } from './shoppingCartReducer';
import { dataActions } from './dataReducer';

const apiClient = axios.create({
  baseURL: environment.apiURL,
  withCredentials: true,
});

export const addDiscountCode =
  (coupon: string, personalData: PersonalData): AppThunk<Promise<void>> =>
  async (dispatch) => {
    const { data } = await apiClient.post<ShoppingCart>(
      'shopping_cart/add_discount/',
      {
        coupons: [coupon],
      }
    );
    dispatch(
      dataActions.setPersonalData({ ...personalData, discountCode: coupon })
    );
    dispatch(shoppingCartActions.shoppingCartLoaded(data));
  };

export const checkout =
  (): AppThunk<Promise<string>> => async (_, getState) => {
    const {
      data: {
        personalData: {
          gender,
          birthDate,
          email,
          password,
          firstName,
          lastName,
          country: personalDataCountry,
          discountCode,
        },

        billingAddressData: {
          addressLine1: billingAddressLine1,
          addressLine2: billingAddressLine2,
          city: billingCity,
          country: billingCountry,
          postCode: billingPostCode,
        },

        deliveryAddressData: {
          addressLine1: deliveryAddressLine1,
          addressLine2: deliveryAddressLine2,
          city: deliveryCity,
          country: deliveryCountry,
          postCode: deliveryPostCode,
        },

        cardData: { cardNumber, expiry, cvc },
      },
      shoppingCart: { id: orderId },
    } = getState();

    const cardExpiryMoment = moment(expiry, 'MM/YY');
    const birthDateMoment = moment(birthDate, 'YYYY-MM-DD');
    const country = billingCountry ? billingCountry : personalDataCountry;

    const checkoutData: Record<string, any> = {
      order_id: orderId,
      coupons: discountCode ? [discountCode] : [],
      order_params: {
        billing_name: 'Billing Address',
        billing_address_attributes: {
          name: 'Billing Address',
          address1: billingAddressLine1,
          address2: billingAddressLine2,
          city: billingCity,
          postcode: billingPostCode,
          country,
          save: true,
        },
        delivery_name: 'Delivery address',
        delivery_address_attributes: { save: false },
      },
      payment_options: {
        card_info: {
          card_number: cardNumber,
          card_month: cardExpiryMoment.get('month') + 1,
          card_year: cardExpiryMoment.get('year'),
          cvc,
        },
      },
      client_data: {
        email,
        first_name: firstName,
        last_name: lastName,
        password,
        gender,
        birth_date: birthDateMoment.format('YYYY-MM-DD'),
      },
    };
    if (deliveryCountry) {
      checkoutData['order_params']['delivery_name'] = 'Delivery address';
      checkoutData['order_params']['delivery_address_attributes'] = {
        address1: deliveryAddressLine1,
        address2: deliveryAddressLine2,
        city: deliveryCity,
        postcode: deliveryPostCode,
        country: deliveryCountry,
        save: false,
      };
    }

    const {
      data: { user_id },
    } = await apiClient.post<{ user_id: string }>(
      'shopping_cart/checkout',
      checkoutData
    );
    return user_id;
  };

export const userEmailAvailable = async (email: string): Promise<boolean> => {
  try {
    const {
      data: { available },
    } = await apiClient.post<{ available: boolean }>('users/email_available', {
      email,
    });
    return available;
  } catch (_) {
    return false;
  }
};
