import { InputLabel } from '@material-ui/core';
import { CSSProperties, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import images from '../../assets/assets';
import { environment } from '../../common/environments';
import useTextInputStyles from './textInputStyles';

const HideShowPassword = (props: {
  showPassword: boolean;
  onPress: () => void;
}) => {
  const { showPassword, onPress } = props;
  const textInputClasses = useTextInputStyles({ smallSpacing: false });
  const { t } = useTranslation();

  const image = useMemo(() => {
    if (showPassword) {
      return images.hidePassword;
    }
    return images.showPassword;
  }, [showPassword]);

  const text = useMemo(() => {
    if (showPassword) {
      return t('hide');
    }
    return t('show');
  }, [showPassword, t]);

  const marginStyle = useMemo<CSSProperties>(() => {
    if (environment.language === 'ar') {
      return { marginLeft: 2 };
    }
    return { marginRight: 2 };
  }, []);

  return (
    <div className={textInputClasses.rowContainer} onClick={onPress}>
      <img src={image} style={marginStyle} width={16} />
      <InputLabel
        className={`${textInputClasses.label} ${textInputClasses.labelNoBottomMargin}`}
      >
        {text}
      </InputLabel>
    </div>
  );
};

export default HideShowPassword;
