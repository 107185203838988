import {
  GridDirection,
  GridItemsAlignment,
  GridSize,
  makeStyles,
  Theme,
  useMediaQuery,
} from '@material-ui/core';
import { useMemo } from 'react';

const useStyles = makeStyles<Theme>((theme: Theme) => {
  return {
    horizontalMargins: {
      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },
  };
});

export default function useMedia() {
  const isDownMD = useMediaQuery('(max-width: 1000px)');

  const direction = useMemo<GridDirection>(() => {
    return isDownMD ? 'column' : 'row';
  }, [isDownMD]);

  const xs = useMemo<GridSize>(() => {
    return isDownMD ? 12 : 6;
  }, [isDownMD]);

  const alignItems = useMemo<GridItemsAlignment>(() => {
    return isDownMD ? 'center' : 'stretch';
  }, [isDownMD]);

  const mediaClasses = useStyles();

  return { direction, xs, mediaClasses, alignItems };
}
