import { Grid, makeStyles, Theme } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { useMemo } from 'react';
import images from '../../assets/assets';
import { environment } from '../../common/environments';
import useMedia from '../../common/media';
import SYDInfo from '../Footer';

const useStyles = makeStyles((theme: Theme) => {
  return {
    text: {
      fontFamily: 'Inter',
      fontSize: 22,
    },

    rightContainer: {
      paddingLeft: theme.spacing(3),
    },

    leftContainerRoot: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: environment.language === 'ar' ? theme.spacing(3) : 0,
        paddingRight: environment.language === 'ar' ? 0 : theme.spacing(3),
      },
    },
  };
});

const LeftPanel = () => {
  const panelClasses = useStyles();
  const theme = useTheme<Theme>();
  const { xs, alignItems } = useMedia();

  const myGPInfo = useMemo(() => {
    return (
      <Grid
        item
        xs={6}
        container
        direction="column"
        wrap="nowrap"
        alignItems={alignItems}
        className={panelClasses.rightContainer}
      >
        <Grid item xs={xs} container direction="column" justify="center">
          <span className={panelClasses.text}>
            <span style={{ color: 'red' }}>50% off</span> with MyGP (£1.50
            monthly)
          </span>
        </Grid>

        <Grid item xs={6}>
          <img src={images.myGPButton} style={{ width: '100%' }} />
        </Grid>
      </Grid>
    );
  }, [alignItems, panelClasses.rightContainer, panelClasses.text, xs]);

  return (
    <Grid
      item
      container
      xs={xs}
      direction="column"
      justify="center"
      className={panelClasses.leftContainerRoot}
    >
      <Grid item style={{ marginBottom: theme.spacing(2) }}>
        <SYDInfo noMargin />
      </Grid>

      <Grid
        item
        container
        direction="row"
        style={{ marginTop: theme.spacing(2) }}
      >
        <Grid item xs={environment.config.showMyGPassets ? 6 : 12}>
          <img src={images.lqiImage} style={{ maxWidth: '100%' }} />
        </Grid>

        {environment.config.showMyGPassets && myGPInfo}
      </Grid>
    </Grid>
  );
};

export default LeftPanel;
