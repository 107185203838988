import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import images from '../assets/assets';
import useLandingPageStyles from '../styles/landingPageStyles';

const useStyles = makeStyles(() => {
  return {
    separator: {
      height: '100%',
      width: 1,
      backgroundColor: 'black',
    },
  };
});

const SYDInfo = (props: { noMargin?: boolean }) => {
  const landingClasses = useLandingPageStyles();
  const componentClasses = useStyles();
  const { noMargin = false } = props;

  const { t } = useTranslation();

  return (
    <Grid
      item
      xs={12}
      container
      direction="row"
      spacing={2}
      className={noMargin ? undefined : landingClasses.formTopMargin}
      style={{ flexWrap: 'nowrap' }}
    >
      <Grid item>
        <img src={images.sydFooter} />
      </Grid>

      <Grid item>
        <div className={componentClasses.separator} />
      </Grid>

      <Grid item style={{ alignSelf: 'center' }}>
        <span style={{ fontFamily: 'Inter' }}>
          {t('yourDailyLifeQualityCompanion')}
        </span>
      </Grid>
    </Grid>
  );
};

export default SYDInfo;
