import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      background:
        'linear-gradient(180deg, #8D09F7 0%, #0ABAB5 48.26%, #F7C824 98.92%)',
      width: '100%',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      height: 1,
    },
  };
});

const Separator = () => {
  const classes = useStyles();

  return <div className={classes.root}></div>;
};

export default Separator;
