import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import analytics from '../../common/analytics';
import useMedia from '../../common/media';
import { dataActions } from '../../redux/reducers/dataReducer';
import useCardValidator from '../../validators/cardValidator';
import PrimaryButton from '../common/PrimaryButton';
import TextInput from '../common/TextInput';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      filter:
        'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)), drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));',
      border: '1px solid rgba(0, 0, 0, 0.5);',
      padding: theme.spacing(3),
      justifyContent: 'center',

      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
      },
    },

    topMargin: {
      marginTop: theme.spacing(4),
    },

    smallTopMargin: {
      marginTop: theme.spacing(2),
    },

    note: {
      fontFamily: 'Inter',
      fontSize: 12,
    },
  };
});

const CardInfoForm = () => {
  const formClasses = useStyles();
  const { buttonDisabled, controlProps, data } = useCardValidator();
  const { xs } = useMedia();

  const dispatch = useDispatch();
  const history = useHistory();

  const { t } = useTranslation();

  const onContinuePressed = useCallback(() => {
    dispatch(dataActions.setCardData(data));

    analytics.sendEvent('Website Card Data Entered');
    history.push('/billing');
  }, [data, dispatch, history]);

  return (
    <Grid
      className={formClasses.root}
      container
      item
      xs={xs}
      direction="column"
    >
      <Grid item>
        <Typography variant="h3">{t('billingInformation')}</Typography>
      </Grid>

      <Grid item>
        <TextInput
          label={t('nameOnTheCard')}
          smallSpacing
          controlProps={controlProps('cardName')}
        />
      </Grid>

      <Grid item>
        <TextInput
          label={t('cardNumber')}
          smallSpacing
          controlProps={controlProps('cardNumber')}
        />
      </Grid>

      <Grid item>
        <TextInput
          label={t('expiry')}
          smallSpacing
          placeholder="MM/YY"
          controlProps={controlProps('expiry')}
        />
      </Grid>

      <Grid item>
        <TextInput
          label={t('cvc')}
          smallSpacing
          controlProps={controlProps('cvc')}
        />
      </Grid>

      <Grid item className={formClasses.topMargin}>
        <PrimaryButton disabled={buttonDisabled} onClick={onContinuePressed}>
          {t('continue')}
        </PrimaryButton>
      </Grid>

      <Grid
        item
        className={formClasses.smallTopMargin}
        container
        justify="center"
      >
        <span className={formClasses.note}>
          {t('yourCardWontBeChargedYet')}
        </span>
      </Grid>
    </Grid>
  );
};

export default CardInfoForm;
