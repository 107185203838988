import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import analytics from '../../common/analytics';
import useMedia from '../../common/media';
import { AppDispatch } from '../../common/types';
import { checkout } from '../../redux/reducers/actions';
import PrimaryButton from '../common/PrimaryButton';
import SummaryPaymentInfo from './SummaryPaymentInfo';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      filter:
        'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)), drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));',
      border: '1px solid rgba(0, 0, 0, 0.5);',
      padding: theme.spacing(3),

      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
      },
    },

    topMargin: {
      marginTop: theme.spacing(4),
    },

    smallTopMargin: {
      marginTop: theme.spacing(2),
    },

    note: {
      fontFamily: 'Inter',
      fontSize: 15,
    },

    contentContainer: {
      flexGrow: 1,
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      maxHeight: 200,
    },
  };
});

const Summary = (props: { onCompleted: () => void }) => {
  const { onCompleted } = props;
  const confirmationClasses = useStyles();
  const { xs } = useMedia();

  const [loading, setLoading] = useState(false);
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();

  const onActivateMyAccountPressed = useCallback(() => {
    if (loading) {
      return;
    }
    setLoading(true);
    dispatch(checkout())
      .then((userId) => {
        setLoading(false);
        analytics.sendEvent('Website Account Created');
        analytics.identifyUser(userId);

        onCompleted();
      })
      .catch(() => {
        setLoading(false);
        alert('Error');
      });
  }, [dispatch, loading, onCompleted]);

  return (
    <Grid
      className={confirmationClasses.root}
      container
      item
      xs={xs}
      direction="column"
      justify="center"
    >
      <Grid item>
        <Typography variant="h3">{t('summary')}</Typography>
      </Grid>

      <Grid
        item
        container
        direction="column"
        style={{ flexGrow: 1 }}
        justify="center"
        className={confirmationClasses.contentContainer}
      >
        <SummaryPaymentInfo cssClass={confirmationClasses.note} />
      </Grid>

      <Grid item className={confirmationClasses.topMargin}>
        <PrimaryButton loading={loading} onClick={onActivateMyAccountPressed}>
          {t('activateMyAccount')}
        </PrimaryButton>
      </Grid>
    </Grid>
  );
};

export default Summary;
