import DateFnsUtils from '@date-io/date-fns';
import { Grid, InputLabel, TextField } from '@material-ui/core';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import moment from 'moment';
import { useMemo, useState } from 'react';
import HideShowPassword from './HideShowPassword';
import useTextInputStyles from './textInputStyles';

const TextInput = (props: Props) => {
  const {
    label,
    date,
    password,
    smallSpacing = false,
    placeholder,
    controlProps: {
      error,
      onChange = () => {},
      onFocus = () => {},
      onBlur = () => {},
      value = '',
    } = {},
  } = props;
  const textInputClasses = useTextInputStyles({ smallSpacing });
  const [showPassword, setShowPassword] = useState(false);

  const type = useMemo(() => {
    let type = 'text';
    if (date) {
      type = 'date';
    }
    if (password && !showPassword) {
      type = 'password';
    }

    return type;
  }, [date, password, showPassword]);

  const field = useMemo(() => {
    if (!props.date) {
      return (
        <TextField
          variant="outlined"
          className={textInputClasses.root}
          helperText={error}
          value={value}
          type={type}
          placeholder={placeholder}
          error={error !== undefined}
          onChange={({ target: { value } }) => {
            onChange(value);
          }}
          onBlur={onBlur}
          onFocus={onFocus}
        />
      );
    } else {
      return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <DatePicker
            format="dd/MM/yyyy"
            value={moment(value, 'YYYY-MM-DD').toDate()}
            onChange={(date) => onChange(moment(date).format('YYYY-MM-DD'))}
            className={textInputClasses.datePicker}
          />
        </MuiPickersUtilsProvider>
      );
    }
  }, [
    error,
    onBlur,
    onChange,
    onFocus,
    placeholder,
    props.date,
    textInputClasses.datePicker,
    textInputClasses.root,
    type,
    value,
  ]);

  return (
    <div className={textInputClasses.container}>
      <Grid container direction="row">
        <Grid item style={{ flexGrow: 1 }}>
          <InputLabel
            className={textInputClasses.label}
            style={{ flexGrow: 1 }}
          >
            {label}
          </InputLabel>
        </Grid>
        <Grid item>
          {password && (
            <HideShowPassword
              showPassword={showPassword}
              onPress={() => setShowPassword((showPassword) => !showPassword)}
            />
          )}
        </Grid>
      </Grid>

      {field}
    </div>
  );
};

interface Props {
  label: string;
  date?: boolean;
  password?: boolean;
  smallSpacing?: boolean;
  placeholder?: string;

  controlProps?: ControlProps;
}

export interface ControlProps {
  error?: string;
  onChange?: (value: string) => void;
  onBlur?: () => void;
  onFocus?: () => void;
  value?: string;
}

export default TextInput;
