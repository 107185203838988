import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import en from './translations/en';
import ar from './translations/ar';
import { environment } from './common/environments';
import moment from 'moment';
import 'moment/locale/ar';

const resources = {
  en: {
    translation: en,
  },
  ar: {
    translation: ar,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: environment.language,

  interpolation: {
    escapeValue: false,
  },
});

moment.locale(environment.language);

export default i18n;
