import { AddressData, CardData, PersonalData, RootState } from './types';

const isPersonalDataCompleted = (personalData: PersonalData) => {
  return (
    personalData.birthDate &&
    personalData.confirmEmail &&
    personalData.confirmPassword &&
    personalData.email &&
    personalData.confirmEmail &&
    personalData.password &&
    personalData.confirmPassword
  );
};

const isCardDataCompleted = (cardData: CardData) => {
  return (
    cardData.cardName && cardData.cardNumber && cardData.cvc && cardData.expiry
  );
};

const isAddressDataCompleted = (addressData: AddressData) => {
  return (
    addressData.addressLine1 &&
    addressData.city &&
    addressData.country &&
    addressData.postCode
  );
};

const checkCardPageRedirects = (state: RootState) => {
  const {
    data: { personalData },
  } = state;

  if (!isPersonalDataCompleted(personalData)) {
    return '/';
  }
  return null;
};

const checkBillingPageRedirects = (state: RootState) => {
  const {
    data: { cardData },
    shoppingCart: { price },
  } = state;
  const free = price === 0;

  const cardRedirects = checkCardPageRedirects(state);
  if (cardRedirects) {
    return cardRedirects;
  }
  if (!free && !isCardDataCompleted(cardData)) {
    return '/card';
  }
  return null;
};

const checkDeliveryPageRedirects = (state: RootState) => {
  const {
    data: { billingAddressData, personalData },
    shoppingCart: { price },
  } = state;
  const free = price === 0;

  if (!free) {
    const billingPageRedirects = checkBillingPageRedirects(state);
    if (billingPageRedirects) {
      return billingPageRedirects;
    }
    if (!isAddressDataCompleted(billingAddressData)) {
      return '/billing';
    }
  } else {
    if (!isPersonalDataCompleted(personalData)) {
      return '/';
    }
  }
  return null;
};

const checkSummaryPageRedirects = (state: RootState) => {
  const {
    data: { personalData, billingAddressData, deliveryAddressData },
    shoppingCart: { price, membership },
  } = state;

  const free = price === 0;
  const premium = membership === 'premium';
  if (premium) {
    const deliveryRedirects = checkDeliveryPageRedirects(state);
    if (deliveryRedirects) {
      return deliveryRedirects;
    }
    if (!isAddressDataCompleted(deliveryAddressData)) {
      return '/delivery';
    }
  } else if (!free) {
    const billingRedirects = checkBillingPageRedirects(state);
    if (billingRedirects) {
      return billingRedirects;
    }
    if (!isAddressDataCompleted(billingAddressData)) {
      return '/billing';
    }
  } else if (!isPersonalDataCompleted(personalData)) {
    return '/';
  }
  return null;
};

const redirectUtils = {
  checkCardPageRedirects,
  checkBillingPageRedirects,
  checkDeliveryPageRedirects,
  checkSummaryPageRedirects,
};

export const convertNumberToArabic = (value: string) => {
  const arabicNumbers =
    '\u0660\u0661\u0662\u0663\u0664\u0665\u0666\u0667\u0668\u0669';
  return new String(value).replace(/[0123456789]/g, (d: string) => {
    const index = parseInt(d);
    return arabicNumbers[index];
  });
};

export default redirectUtils;
