import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import data from './reducers/dataReducer';
import shoppingCart from './reducers/shoppingCartReducer';
import thunk from 'redux-thunk';

export const store = configureStore({
  reducer: {
    data,
    shoppingCart,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});
