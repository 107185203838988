import './App.css';
import { HashRouter as Router, Switch, Route } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import { ThemeProvider } from '@material-ui/styles';
import theme from './styles/theme';
import CardInfoPage from './pages/CardInfoPage';
import AddressPage from './pages/AddressPage';
import SummaryPage from './pages/SummaryPage';
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { useEffect } from 'react';
import { environment } from './common/environments';

function App() {
  useEffect(() => {
    if (environment.language === 'ar') {
      document.body.dir = 'rtl';
    }
  }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Router>
          <Switch>
            <Route exact path="/">
              <LandingPage />
            </Route>

            <Route exact path="/card">
              <CardInfoPage />
            </Route>

            <Route exact path="/billing">
              <AddressPage delivery={false} />
            </Route>

            <Route exact path="/delivery">
              <AddressPage delivery={true} />
            </Route>

            <Route exact path="/confirmation">
              <SummaryPage />
            </Route>
          </Switch>
        </Router>
      </ThemeProvider>
    </Provider>
  );
}

export default App;
