import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ShoppingCart } from '../../common/networkTypes';

interface State {
  id: number;
  price: number;
  subscription: boolean;
  membership: 'lite' | 'premium';
  hasLoaded: boolean;
}

const initialState: State = {
  id: 0,
  price: 0,
  subscription: false,
  membership: 'lite',
  hasLoaded: false,
};

const shoppintCartSlice = createSlice({
  name: 'shoppingCart',
  initialState,
  reducers: {
    shoppingCartLoaded: (state, action: PayloadAction<ShoppingCart>) => {
      let displayPrice = 0;
      let subscription = false;
      let membership: State['membership'] = 'lite';

      const { display_price = 0, items = [], id } = action.payload;
      displayPrice = display_price;

      if (items.length > 0) {
        const {
          product: { code = 'simple' } = { code: 'simple' },
          subscription: itemSubscription,
        } = items[0];
        membership = code === 'simple' ? 'lite' : 'premium';

        if (itemSubscription) {
          subscription = true;
          displayPrice = itemSubscription!.price_pounds;
        }
      }

      state.id = id;
      state.price = displayPrice;
      state.membership = membership;
      state.subscription = subscription;
      state.hasLoaded = true;
    },
  },
  extraReducers: {
    'redux/reset': () => {
      return { ...initialState };
    },
  },
});

export default shoppintCartSlice.reducer;

export const shoppingCartActions = shoppintCartSlice.actions;
