import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useMedia from '../../common/media';
import { useStyles as useSummaryStyles } from './Summary';

const Congratulations = () => {
  const confirmationClasses = useSummaryStyles();
  const { xs } = useMedia();
  const { t } = useTranslation();

  return (
    <Grid
      className={confirmationClasses.root}
      container
      item
      xs={xs}
      direction="column"
      justify="center"
    >
      <Grid item>
        <Typography variant="h3">{t('summary')}</Typography>
      </Grid>

      <Grid
        item
        container
        direction="column"
        style={{ flexGrow: 1 }}
        justify="space-around"
        className={confirmationClasses.contentContainer}
      >
        <p className={confirmationClasses.note}>
          {t('congratulationAndWelcomeToSYD')}
        </p>
        <p className={confirmationClasses.note}>{t('toAccessSYD')}</p>
        <a
          className={confirmationClasses.note}
          href="https://apps.apple.com/gb/app/syd/id1428673549"
        >
          {t('downloadFromApple')}
        </a>
        <a
          className={confirmationClasses.note}
          href="https://play.google.com/store/apps/details?id=com.iamyiam.iamyiam&hl=en_GB&gl=US"
        >
          {t('downloadFromGoogle')}
        </a>
      </Grid>
    </Grid>
  );
};

export default Congratulations;
