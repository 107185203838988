import { InputLabel, MenuItem, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { ControlProps } from './TextInput';
import useTextInputStyles from './textInputStyles';

const useStyles = makeStyles(() => {
  return {
    root: {
      '&.MuiOutlinedInput-root': {
        width: '100%',
      },
      '& .MuiSelect-select': {
        fontSize: '16px',
        fontFamily: 'Inter',
      },
    },

    text: {
      fontSize: 16,
      fontFamily: 'Inter',
    },
  };
});

const SelectInput = (props: Props) => {
  const { label, controlProps: { onChange = () => {}, value = 'M' } = {} } =
    props;
  const selectClasses = useStyles();
  const textInputClasses = useTextInputStyles({ smallSpacing: false });
  const { t } = useTranslation();

  return (
    <div className={textInputClasses.container}>
      <InputLabel classes={{ root: textInputClasses.label }}>
        {label}
      </InputLabel>

      <Select
        className={selectClasses.root}
        variant="outlined"
        value={value}
        onChange={({ target: { value } }) => onChange(value as string)}
      >
        <MenuItem value="M" className={selectClasses.text}>
          {t('male')}
        </MenuItem>
        <MenuItem value="F" className={selectClasses.text}>
          {t('female')}
        </MenuItem>
        <MenuItem value="N/B" className={selectClasses.text}>
          {t('nonBinary')}
        </MenuItem>
      </Select>
    </div>
  );
};

interface Props {
  label: string;
  controlProps: ControlProps;
}

export default SelectInput;
