import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import useMedia from '../common/media';
import { RootState } from '../common/types';
import redirectUtils from '../common/utils';
import CardInfoForm from '../components/billing/CardInfoForm';
import LeftPanel from '../components/billing/LeftPanel';
import useLandingPageStyles from '../styles/landingPageStyles';

const CardInfoPage = () => {
  const landingClasses = useLandingPageStyles();
  const { direction } = useMedia();

  const state = useSelector<RootState, RootState>((state) => state);
  const redirect = redirectUtils.checkCardPageRedirects(state);
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Grid container direction={direction} className={landingClasses.root}>
      <LeftPanel />

      <CardInfoForm />
    </Grid>
  );
};

export default CardInfoPage;
