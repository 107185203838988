import { makeStyles, Theme } from '@material-ui/core';
import { environment } from '../common/environments';

const useLandingPageStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      paddingLeft: theme.spacing(8),
      paddingRight: theme.spacing(8),
      paddingTop: theme.spacing(15),
      paddingBottom: theme.spacing(15),

      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
      },
    },

    lqiImage: {
      maxWidth: '100%',
      borderRadius: 12,
    },

    myGPButton: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      paddingLeft: theme.spacing(3),
    },

    myGPRightContainer: {
      marginLeft: theme.spacing(3),
    },

    caption: {
      color: theme.texts.caption,
    },

    buttonContainer: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(2),
    },

    button: {
      flexGrow: 0.4,
      [theme.breakpoints.down('md')]: {
        flexGrow: 1,
      },
    },

    topSpace: {
      marginTop: theme.spacing(2),
    },

    formContainer: {
      paddingLeft: theme.spacing(40),
      paddingRight: theme.spacing(40),
      width: '100%',
      marginTop: theme.spacing(10),

      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
        marginTop: theme.spacing(4),
      },
    },

    formTopMargin: {
      marginTop: theme.spacing(6),
    },

    formSmallMargin: {
      marginTop: theme.spacing(4),
    },

    formHorizontalMargin: {
      paddingLeft: theme.spacing(0),
      paddingRight: theme.spacing(0),
    },

    formSpace: {
      paddingLeft: theme.spacing(environment.language === 'ar' ? 0 : 3),
      paddingRight: theme.spacing(environment.language === 'ar' ? 3 : 0),

      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(0),
        paddingRight: theme.spacing(0),
      },
    },

    termsAndConditions: {
      fontFamily: 'Inter',
      fontSize: 13,
    },
  };
});

export default useLandingPageStyles;
