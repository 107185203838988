import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useAutoCompleteData } from '../common/autoCompleteData';
import { AddressData, RootState } from '../common/types';
import { ControlProps } from '../components/common/TextInput';

type AddressDataKey = keyof AddressData;

type Errors = { [key in AddressDataKey]?: string };

export default function useAddressValidator() {
  const country = useSelector<RootState, string>(
    (state) => state.data.personalData.country!
  );

  const { addressData } = useAutoCompleteData();
  const [data, setData] = useState<AddressData>(addressData(country));
  const [errors, setErrors] = useState<Errors>({});
  const { t } = useTranslation();

  const validate = useCallback(
    (key: AddressDataKey) => {
      if (key !== 'addressLine2' && !data[key]) {
        setErrors((errors) => ({ ...errors, [key]: t('thisFieldIsRequired') }));
        return;
      }
    },
    [data, t]
  );

  const controlProps = useCallback<(key: AddressDataKey) => ControlProps>(
    (key: AddressDataKey) => {
      return {
        error: errors[key],
        onFocus: () => setErrors((errors) => ({ ...errors, [key]: undefined })),
        onBlur: () => validate(key),
        onChange: (value) => setData((data) => ({ ...data, [key]: value })),
        value: data[key],
      };
    },
    [data, errors, validate]
  );

  const buttonDisabled = useMemo(() => {
    //make sure all fields are completed
    if (!data.addressLine1 || !data.city || !data.country || !data.postCode) {
      return true;
    }

    return false;
  }, [data]);

  return { controlProps, buttonDisabled, data };
}
