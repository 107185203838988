import { createMuiTheme } from '@material-ui/core';
import { TypographyStyleOptions } from '@material-ui/core/styles/createTypography';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    texts: {
      caption: React.CSSProperties['color'];
    };
  }

  interface ThemeOptions {
    texts: {
      caption: React.CSSProperties['color'];
    };
  }
}

declare module '@material-ui/core/styles/createTypography' {
  interface TypographyOptions {
    inputFieldLabel?: TypographyStyleOptions;
  }
}

const theme = createMuiTheme({
  texts: {
    caption: '#676767',
  },

  palette: {
    primary: { main: '#8D09F7' },
  },

  typography: {
    fontFamily: 'Inter',

    h1: {
      fontWeight: 600,
      fontSize: 52,
      lineHeight: '64px',
    },

    h2: {
      fontWeight: 600,
      fontSize: 33,
    },

    h3: {
      fontWeight: 600,
      fontSize: 24,
    },

    body1: {
      fontSize: 20,
      lineHeight: '32px',
      fontWeight: 'normal',
    },

    caption: {
      fontSize: 16,
      lineHeight: '27px',
    },
  },
});

export default theme;
