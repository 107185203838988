const en = {
  supportingYouWithWhatYouNeed:
    'Supporting you with what you need, when you need it.',
  sydYourDailyLifeQualityCompanion: 'syd™ – your daily life quality companion',
  signUpNow: 'Sign Up Now',
  joinSydToday: 'Join syd today',
  firstName: 'First name',
  lastName: 'Last name',
  gender: 'Gender',
  birthDate: 'Birthdate',
  yourEmail: 'Your email',
  confirmYourEmail: 'Confirm your email',
  yourPassword: 'Your password',
  confirmYourPassword: 'Confirm your password',
  signUp: 'Sign Up',
  byContinuingToUseSYD: 'By continuing to use syd, you agree to our',
  termsAndConditions: 'Terms and Conditions',
  bothUppercaseAndLowercaseLetters: 'Both uppercase and lowercase letters',
  atLeast8Characters: 'At least 8 characters',
  mixtureOfLettersAndNumbers: 'Mixture of letters and numbers',
  atLeastOneSpecialCharacter: 'At least one special character, e.g., ! @ # ?',
  yourDailyLifeQualityCompanion:
    'your daily life quality companion supporting you with what you need when you need it',
  billingInformation: 'Billing Information',
  nameOnTheCard: 'Name on the card',
  cardNumber: 'Card Number',
  expiry: 'Expiry',
  cvc: 'CVC',
  continue: 'Continue',
  yourCardWontBeChargedYet: 'Your card won’t be charged yet',
  addressLine1: 'Address Line 1',
  addressLine2: 'Address Line 2',
  townCity: 'Town / City',
  postalZipCode: 'Postal / Zip code',
  selectACountry: 'Select a country',
  summary: 'Summary',
  monthlySubscription: 'Monthly Subscription',
  total: 'Total',
  nextPayment: 'Next Payment',
  activateMyAccount: 'Activate my account',
  congratulationAndWelcomeToSYD: 'Congratulations and welcome to syd!',
  toAccessSYD:
    'To access syd, download the app and sign in with your email address and password:',
  downloadFromApple: 'Download from the Apple Appstore',
  downloadFromGoogle: 'Download from the Google Playstore',
  hide: 'Hide',
  show: 'Show',
  male: 'Male',
  female: 'Female',
  nonBinary: 'Non Binary',
  egMyGP50: 'e.g. MyGP50',
  yourMyGPAccessCode: 'Your MyGP Access Code',
  discountCode: 'Access code',
  yourDiscountCode: 'Your access code',
  oneOffPayment: 'One off payment',
  deliveryInformation: 'Delivery Information',
  thisFieldIsRequired: 'This field is required',
  invalidEmail: 'Invalid email',
  thisEmailIsAlreadyRegistered:
    'This email is already registered.  Try logging in with it.',
  passwordDoesNotMeet: 'Password does not meet strength requirements',
  passwordsDoNotMatch: 'Passwords do not match',
  emailsDoNotMatch: 'Emails do not match',
  birthDateCannotBeInTheFuture: 'Birth date cannot be in the future',
  invalidCardNumber: 'Invalid card number',
  invalidExpiryDate: 'Invalid expiry date',
  cardExpiryIsInThePast: 'Card expiry is in the past',
  invalidCVC: 'Invalid CVC',
  anErrorOccuredDiscountCode:
    "The access code you've entered is not eligible for this offer.",
  birthDateCannotBeAfter: 'Birthdate cannot be after {{0}}',
};

export default en;
