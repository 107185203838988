import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from '../common/types';
import redirectUtils from '../common/utils';
import Summary from '../components/billing/Summary';
import Congratulations from '../components/billing/Congratulations';
import LeftPanel from '../components/billing/LeftPanel';
import useLandingPageStyles from '../styles/landingPageStyles';
import { useState } from 'react';

const SummaryPage = () => {
  const landingClasses = useLandingPageStyles();
  const [completed, setCompleted] = useState(false);

  const state = useSelector<RootState, RootState>((state) => state);
  const redirect = redirectUtils.checkSummaryPageRedirects(state);
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Grid container direction="row" className={landingClasses.root}>
      <LeftPanel />

      {completed ? (
        <Congratulations />
      ) : (
        <Summary onCompleted={() => setCompleted(true)} />
      )}
    </Grid>
  );
};

export default SummaryPage;
