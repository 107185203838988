import { Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { RootState } from '../common/types';
import redirectUtils from '../common/utils';
import BillingInfoForm from '../components/billing/BillingInfoForm';
import LeftPanel from '../components/billing/LeftPanel';
import useLandingPageStyles from '../styles/landingPageStyles';

const AddressPage = (props: { delivery: boolean }) => {
  const { delivery } = props;
  const landingClasses = useLandingPageStyles();

  const state = useSelector<RootState, RootState>((state) => state);
  const redirect = delivery
    ? redirectUtils.checkDeliveryPageRedirects(state)
    : redirectUtils.checkBillingPageRedirects(state);
  if (redirect) {
    return <Redirect to={redirect} />;
  }

  return (
    <Grid container direction="row" className={landingClasses.root}>
      <LeftPanel />

      <BillingInfoForm delivery={delivery} />
    </Grid>
  );
};

export default AddressPage;
