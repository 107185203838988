import amplitude from 'amplitude-js';
import { store } from '../redux/store';
import { environment } from './environments';

declare var _cio: {
  identify: (props: { id: string; email: string; code: string }) => void;
};
const customerIO = _cio;

class Analytics {
  init = () => amplitude.getInstance().init(environment.amplitudeAPIKey);

  sendEvent = (event: string) => {
    amplitude.getInstance().logEvent(event);
  };

  identificationData = () => {
    const {
      data: {
        personalData: {
          discountCode = '',
          email = '',
          firstName = '',
          lastName = '',
        },
        billingAddressData: { city = '', country = '' } = {
          city: '',
          country: '',
        },
        deliveryAddressData: {
          city: deliveryCity = '',
          country: deliveryCountry = '',
        } = {
          city: '',
          country: '',
        },
      },
      shoppingCart: { membership = 'lite' } = { membership: 'lite' },
    } = store.getState();

    return {
      email,
      Email: email,
      FirstName: firstName,
      LastName: lastName,
      BillingCity: city,
      BillingCountry: country,
      DeliveryCity: deliveryCity,
      DeliveryCountry: deliveryCountry,
      ValueType: membership === 'lite' ? 'lite' : 'premium',
      HasCheckedGeneticResults: false,
      Onboarding: false,
      Locale: environment.language,
      code: discountCode,
    };
  };

  identifyUser = (userId: string) => {
    const {} = store.getState();
    const userProperties = this.identificationData();

    amplitude.getInstance().setUserId(userId);
    amplitude.getInstance().setUserProperties(userProperties);
    customerIO.identify({ id: userId, ...userProperties });
  };
}

const analytics = new Analytics();
analytics.init();

export default analytics;
