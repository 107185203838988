import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAutoCompleteData } from '../common/autoCompleteData';
import { PersonalData } from '../common/types';
import { ControlProps } from '../components/common/TextInput';
import { userEmailAvailable } from '../redux/reducers/actions';
import validator from './utils';

const useSignUpValidation = () => {
  const { personalData } = useAutoCompleteData();
  const [data, setData] = useState<PersonalData>(personalData);
  const [errors, setErrors] = useState<FormErrors>({});

  const { t } = useTranslation();

  const validate = useCallback(
    (key: FormDataKey) => {
      if (key !== 'discountCode' && !data[key]) {
        setErrors((errors) => ({
          ...errors,
          [key]: t('thisFieldIsRequired'),
        }));
        return;
      }

      if (key === 'email') {
        if (!validator.validEmail(data.email)) {
          setErrors((errors) => ({ ...errors, email: t('invalidEmail') }));
          return;
        }
        userEmailAvailable(data.email!).then((valid) => {
          if (!valid) {
            setErrors((errors) => ({
              ...errors,
              email: t('thisEmailIsAlreadyRegistered'),
            }));
          }
        });
      }

      if (key === 'password') {
        if (!validator.validPassword(data.password)) {
          setErrors((errors) => ({
            ...errors,
            password: t('passwordDoesNotMeet'),
          }));
          return;
        }
      }

      if (key === 'confirmPassword') {
        if (data.password !== data.confirmPassword) {
          setErrors((errors) => ({
            ...errors,
            confirmPassword: t('passwordsDoNotMatch'),
          }));
          return;
        }
      }

      if (key === 'confirmEmail') {
        if (data.email !== data.confirmEmail) {
          setErrors((errors) => ({
            ...errors,
            confirmEmail: t('emailsDoNotMatch'),
          }));
          return;
        }
      }

      if (key === 'birthDate') {
        const birthDateMoment = moment(data.birthDate, 'YYYY-MM-DD');
        if (birthDateMoment.isSameOrAfter(moment())) {
          setErrors((errors) => ({
            ...errors,
            birthDate: t('birthDateCannotBeInTheFuture'),
          }));
          return;
        }

        const thirteenYearsAgo = moment().subtract(13, 'years');
        if (birthDateMoment.isAfter(thirteenYearsAgo)) {
          setErrors((errors) => ({
            ...errors,
            birthDate: t('birthDateCannotBeAfter', {
              0: thirteenYearsAgo.format('DD/MM/YYYY'),
            }),
          }));
        }
      }
    },
    [data, t]
  );

  const controlProps = useCallback<(key: FormDataKey) => ControlProps>(
    (key: FormDataKey) => {
      return {
        error: errors[key],
        onFocus: () => setErrors((errors) => ({ ...errors, [key]: undefined })),
        onBlur: () => validate(key),
        onChange: (value) => setData((data) => ({ ...data, [key]: value })),
        value: data[key],
      };
    },
    [data, errors, validate]
  );

  const buttonDisabled = useMemo(() => {
    //make sure all fields are completed
    if (
      !data.firstName ||
      !data.lastName ||
      !data.gender ||
      !data.birthDate ||
      !data.email ||
      !data.confirmEmail ||
      !data.password ||
      !data.confirmPassword
    ) {
      return true;
    }

    if (data.password !== data.confirmPassword) {
      return true;
    }
    if (data.email !== data.confirmEmail) {
      return true;
    }
    if (!validator.validEmail(data.email)) {
      return true;
    }
    if (!validator.validPassword(data.password)) {
      return true;
    }

    const birthDateMoment = moment(data.birthDate, 'YYYY-MM-DD');
    const thirteenYearsAgo = moment().subtract(13, 'years');
    if (
      birthDateMoment.isSameOrAfter(moment()) ||
      birthDateMoment.isAfter(thirteenYearsAgo)
    ) {
      return true;
    }

    return false;
  }, [data]);

  return { controlProps, buttonDisabled, data };
};

type FormDataKey = keyof PersonalData;

type FormErrors = { [key in FormDataKey]?: string };

export default useSignUpValidation;
