import { Grid, Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import analytics from '../../common/analytics';
import useMedia from '../../common/media';
import { RootState } from '../../common/types';
import { dataActions } from '../../redux/reducers/dataReducer';
import useAddressValidator from '../../validators/addressValidator';
import CountrySelect from '../common/CountrySelect';
import PrimaryButton from '../common/PrimaryButton';
import TextInput from '../common/TextInput';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      filter:
        'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25)), drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));',
      border: '1px solid rgba(0, 0, 0, 0.5);',
      padding: theme.spacing(3),
      justifyContent: 'center',

      [theme.breakpoints.down('md')]: {
        marginTop: theme.spacing(4),
      },
    },

    topMargin: {
      marginTop: theme.spacing(4),
    },

    smallTopMargin: {
      marginTop: theme.spacing(2),
    },

    note: {
      fontFamily: 'Inter',
      fontSize: 12,
    },
  };
});

const BillingInfoForm = (props: Props) => {
  const formClasses = useStyles();
  const { controlProps, data, buttonDisabled } = useAddressValidator();

  const dispatch = useDispatch();
  const history = useHistory();

  const { xs } = useMedia();
  const { t } = useTranslation();
  const { delivery } = props;

  const setReduxData = useCallback(() => {
    if (!delivery) {
      dispatch(dataActions.setBillingAddressData(data));
    } else {
      dispatch(dataActions.setDeliveryAddressData(data));
    }
  }, [data, delivery, dispatch]);

  const premium = useSelector<RootState, boolean>(
    (state) => state.shoppingCart.membership === 'premium'
  );

  const nextScreen = useMemo(() => {
    if (!delivery && premium) {
      return '/delivery';
    } else {
      return '/confirmation';
    }
  }, [delivery, premium]);

  const onContinuePressed = useCallback(() => {
    setReduxData();

    analytics.sendEvent('Website Address Data Entered');
    history.push(nextScreen);
  }, [history, nextScreen, setReduxData]);

  const title = useMemo(() => {
    if (delivery) {
      return t('deliveryInformation');
    }
    return t('billingInformation');
  }, [delivery, t]);

  return (
    <Grid
      className={formClasses.root}
      container
      item
      xs={xs}
      direction="column"
    >
      <Grid item>
        <Typography variant="h3">{title}</Typography>
      </Grid>

      <Grid item>
        <TextInput
          label={t('addressLine1')}
          smallSpacing
          controlProps={controlProps('addressLine1')}
        />
      </Grid>

      <Grid item>
        <TextInput
          label={t('addressLine2')}
          smallSpacing
          controlProps={controlProps('addressLine2')}
        />
      </Grid>

      <Grid item>
        <TextInput
          label={t('townCity')}
          smallSpacing
          controlProps={controlProps('city')}
        />
      </Grid>

      <Grid item>
        <TextInput
          label={t('postalZipCode')}
          smallSpacing
          controlProps={controlProps('postCode')}
        />
      </Grid>

      <Grid item>
        <CountrySelect controlProps={controlProps('country')} />
      </Grid>

      <Grid item className={formClasses.topMargin}>
        <PrimaryButton disabled={buttonDisabled} onClick={onContinuePressed}>
          {t('continue')}
        </PrimaryButton>
      </Grid>

      <Grid
        item
        className={formClasses.smallTopMargin}
        container
        justify="center"
      >
        <span className={formClasses.note}>
          {t('yourCardWontBeChargedYet')}
        </span>
      </Grid>
    </Grid>
  );
};

interface Props {
  delivery: boolean;
}

export default BillingInfoForm;
