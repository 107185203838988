import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAutoCompleteData } from '../common/autoCompleteData';
import { CardData } from '../common/types';
import { ControlProps } from '../components/common/TextInput';
import validator from './utils';

type CardDataKey = keyof CardData;

type Errors = { [key in CardDataKey]?: string };

const useCardValidator = () => {
  const { cardData } = useAutoCompleteData();
  const [data, setData] = useState<CardData>(cardData);
  const [errors, setErrors] = useState<Errors>({});
  const { t } = useTranslation();

  const validate = useCallback(
    (key: CardDataKey) => {
      if (!data[key]) {
        setErrors((errors) => ({ ...errors, [key]: t('thisFieldIsRequired') }));
        return;
      }

      if (key === 'cardNumber') {
        if (!validator.validCardNumber(data.cardNumber)) {
          setErrors((errors) => ({
            ...errors,
            cardNumber: t('invalidCardNumber'),
          }));
          return;
        }
      }

      if (key === 'expiry') {
        if (!validator.validCardExpiry(data.expiry)) {
          setErrors((errors) => ({
            ...errors,
            expiry: t('invalidExpiryDate'),
          }));
          return;
        }

        if (validator.cardExpiryIsInPast(data.expiry!)) {
          setErrors((errors) => ({
            ...errors,
            expiry: t('cardExpiryIsInThePast'),
          }));
          return;
        }
      }

      if (key === 'cvc') {
        if (data.cvc!.length < 3) {
          setErrors((errors) => ({ ...errors, cvc: t('invalidCVC') }));
        }
      }
    },
    [data, t]
  );

  const formatExpiryDate = useCallback((value: string) => {
    if (value.length > 5) {
      return;
    }
    let formattedValue = value.replace('/', '');
    if (formattedValue.length > 2) {
      formattedValue =
        formattedValue.substring(0, 2) + '/' + formattedValue.substring(2);
    }

    setData((data) => ({ ...data, expiry: formattedValue }));
  }, []);

  const controlProps = useCallback<(key: CardDataKey) => ControlProps>(
    (key: CardDataKey) => {
      return {
        error: errors[key],
        onFocus: () => setErrors((errors) => ({ ...errors, [key]: undefined })),
        onBlur: () => validate(key),
        onChange: (value) => {
          if (key === 'expiry') {
            formatExpiryDate(value);
          } else {
            setData((data) => ({ ...data, [key]: value }));
          }
        },
        value: data[key],
      };
    },
    [data, errors, formatExpiryDate, validate]
  );

  const buttonDisabled = useMemo(() => {
    //make sure all fields are completed
    if (!data.cardName || !data.cardNumber || !data.cvc || !data.expiry) {
      return true;
    }
    if (!validator.validCardNumber(data.cardNumber)) {
      return true;
    }
    if (validator.cardExpiryIsInPast(data.expiry)) {
      return true;
    }
    if (validator.validCardNumber(data.expiry)) {
      return true;
    }

    return false;
  }, [data]);

  return { controlProps, buttonDisabled, data };
};

export default useCardValidator;
