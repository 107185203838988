import landingPageBanner from './images/landing_page_banner.png';
import myGPButton from './images/mygp_button.png';
import lqiImage from './images/LQI_image.webp';
import logo from './images/logo.svg';
import showPassword from './images/show_password.png';
import hidePassword from './images/hide_password.png';
import passwordRequirementBullet from './images/password_requirements_bullet.png';
import passwordRequirementBulletFilled from './images/password_requirements_bullet_filled.png';
import sydFooter from './images/syd_footer.png';

const images = {
  landingPageBanner,
  myGPButton,
  lqiImage,
  logo,
  showPassword,
  hidePassword,
  passwordRequirementBullet,
  passwordRequirementBulletFilled,
  sydFooter,
};

export default images;
