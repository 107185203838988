export const myGPConfig = {
  showMyGPassets: true,
  discountCode: 'MyGP50',
  discountCodePlaceholderKey: 'egMyGP50',
  discountCodeLabelKey: 'yourMyGPAccessCode',
};

export type Config = typeof myGPConfig;

export const sydConfig: Config = {
  showMyGPassets: false,
  discountCode: '',
  discountCodePlaceholderKey: 'discountCode',
  discountCodeLabelKey: 'yourDiscountCode',
};
