import { Grid, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useCallback } from 'react';
import images from '../../assets/assets';
import useMedia from '../../common/media';
import useTextInputStyles from '../common/textInputStyles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      marginTop: theme.spacing(3),
      paddingLeft: theme.spacing(3),
    },
  };
});

const PasswordRequirements = () => {
  const inputStyles = useTextInputStyles({ smallSpacing: false });
  const componentStyles = useStyles();
  const { mediaClasses } = useMedia();

  const { t } = useTranslation();

  const info = useCallback(
    (text: string) => {
      return (
        <div className={inputStyles.rowContainer}>
          <img
            src={images.passwordRequirementBullet}
            style={{ marginRight: 5 }}
          />
          <span className={inputStyles.label}>{text}</span>
        </div>
      );
    },
    [inputStyles.label, inputStyles.rowContainer]
  );

  return (
    <Grid
      item
      container
      xs={12}
      direction="row"
      className={`${componentStyles.root} ${mediaClasses.horizontalMargins}`}
    >
      <Grid item container xs={6} direction="column">
        {[t('bothUppercaseAndLowercaseLetters'), t('atLeast8Characters')].map(
          info
        )}
      </Grid>
      <Grid item container xs={6} direction="column">
        {[t('mixtureOfLettersAndNumbers'), t('atLeastOneSpecialCharacter')].map(
          info
        )}
      </Grid>
    </Grid>
  );
};

export default PasswordRequirements;
