import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AddressData, CardData, PersonalData } from '../../common/types';

interface State {
  personalData: PersonalData;
  cardData: CardData;
  billingAddressData: AddressData;
  deliveryAddressData: AddressData;
}

const emptyAddress: AddressData = {
  addressLine1: '',
  addressLine2: '',
  city: '',
  country: '',
  postCode: '',
};

const initialState: State = {
  personalData: {
    firstName: '',
    gender: 'M',
    birthDate: '',
    confirmEmail: '',
    confirmPassword: '',
    discountCode: '',
    email: '',
    lastName: '',
    password: '',
  },

  cardData: {
    cardName: '',
    cardNumber: '',
    cvc: '',
    expiry: '',
  },

  billingAddressData: emptyAddress,
  deliveryAddressData: emptyAddress,
};

const dataSlice = createSlice({
  name: 'data',
  initialState,
  reducers: {
    setPersonalData: (state, action: PayloadAction<PersonalData>) => {
      state.personalData = action.payload;
    },

    setCardData: (state, action: PayloadAction<CardData>) => {
      state.cardData = action.payload;
    },

    setBillingAddressData: (state, action: PayloadAction<AddressData>) => {
      state.billingAddressData = action.payload;
    },

    setDeliveryAddressData: (state, action: PayloadAction<AddressData>) => {
      state.deliveryAddressData = action.payload;
    },
  },
  extraReducers: {
    'redux/reset': () => {
      return { ...initialState };
    },
  },
});

export const dataActions = dataSlice.actions;

export default dataSlice.reducer;
