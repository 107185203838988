import { Button, CircularProgress, styled } from '@material-ui/core';
import { makeStyles, withTheme } from '@material-ui/styles';
import { ComponentProps } from 'react';

const PrimaryButton = styled(withTheme(Button))((props) => ({
  backgroundColor: props.theme.palette.primary.main,
  color: 'white',
  flexGrow: 1,
  paddingTop: props.theme.spacing(2),
  paddingBottom: props.theme.spacing(2),
  width: '100%',

  fontFamily: 'Inter',
  fontWeight: 800,

  '&:hover': {
    opacity: 0.7,
    backgroundColor: props.theme.palette.primary.main,
  },

  '&.Mui-disabled': {
    backgroundColor: '#c27cfb',
  },
}));

const useStyles = makeStyles(() => {
  return {
    container: {
      '&.MuiCircularProgress-colorPrimary': {
        color: 'white',
      },
    },
  };
});

export default function ButtonWithLoad(props: Props) {
  const { loading, children } = props;
  const buttonClasses = useStyles();

  return (
    <PrimaryButton {...props}>
      {!loading ? (
        children
      ) : (
        <CircularProgress className={buttonClasses.container} />
      )}
    </PrimaryButton>
  );
}

type Props = ComponentProps<typeof PrimaryButton> & { loading?: boolean };
