import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { environment } from './environments';
import { AddressData, CardData, PersonalData } from './types';

export const useAutoCompleteData = () => {
  const personalData = useMemo<PersonalData>(() => {
    if (environment.autoCompleteFields) {
      return {
        gender: 'M',
        birthDate: '1989-04-04',
        email: 'user_test_website28@mailinator.com',
        confirmEmail: 'user_test_website28@mailinator.com',
        password: 'Password_1',
        confirmPassword: 'Password_1',
        country: 'GB',
        // discountCode: 'test_website_premium_50',
        firstName: 'Lucian',
        lastName: 'Test',
      };
    } else {
      return {
        birthDate: moment().subtract(20, 'years').format('YYYY-MM-DD'),
        gender: 'F',
        discountCode: environment.config.discountCode,
        country: 'GB',
      };
    }
  }, []);

  const addressData = useCallback<(selectedCountry: string) => AddressData>(
    (selectedCountry: string) => {
      if (environment.autoCompleteFields) {
        return {
          addressLine1: 'Address Line 1',
          addressLine2: 'Address Line 2',
          city: 'London',
          postCode: 'SW1',
          country: 'GB',
        };
      } else {
        return { country: selectedCountry };
      }
    },
    []
  );

  const cardData = useMemo<CardData>(() => {
    if (environment.autoCompleteFields) {
      return {
        cardName: 'Lucian',
        cardNumber: '4242424242424242',
        expiry: '12/25',
        cvc: '200',
      };
    } else {
      return {};
    }
  }, []);

  return { personalData, addressData, cardData };
};
