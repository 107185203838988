import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      background: 'black',
      width: '100%',
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
      height: 1,
    },
  };
});

const BlackSeparator = () => {
  const classes = useStyles();

  return <div className={classes.root}></div>;
};

export default BlackSeparator;
