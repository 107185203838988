import { Grid, TextField, Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import { environment } from '../../common/environments';
import useMedia from '../../common/media';
import { ControlProps } from '../common/TextInput';

const useStyles = makeStyles((theme: Theme) => {
  return {
    root: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      backgroundColor: '#D4D4D4',

      [theme.breakpoints.down('md')]: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
      },
    },

    input: {
      '& .MuiOutlinedInput-root': {
        backgroundColor: 'white',
      },

      '& input': {
        fontFamily: 'Inter',
      },

      '& .MuiFormHelperText-root.Mui-error': {
        fontFamily: 'Inter',
        fontSize: '12px',
        color: 'red',
        marginTop: 0,
        marginLeft: 0,
      },

      '& .Mui-error': {
        color: 'red',
      },
    },

    label: {
      fontFamily: 'Inter',
    },
  };
});

const DiscountCode = (props: { controlProps: ControlProps }) => {
  const {
    controlProps: {
      onFocus,
      onBlur,
      onChange = () => {},
      error,
      value = '',
    } = {},
  } = props;
  const componentClasses = useStyles();
  const { mediaClasses } = useMedia();
  const { t } = useTranslation();

  return (
    <div className={mediaClasses.horizontalMargins}>
      <Grid
        item
        container
        xs={12}
        direction="row"
        className={`${componentClasses.root}`}
      >
        <Grid item container xs={6} justify="center" alignItems="center">
          <span className={componentClasses.label}>
            {t(environment.config.discountCodeLabelKey)}
          </span>
        </Grid>

        <Grid item container xs={6} justify="center" alignItems="center">
          <TextField
            placeholder={t(environment.config.discountCodePlaceholderKey)}
            variant="outlined"
            className={componentClasses.input}
            helperText={error}
            error={error !== undefined}
            onFocus={onFocus}
            value={value}
            onChange={({ target: { value } }) => onChange(value)}
            onBlur={onBlur}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default DiscountCode;
